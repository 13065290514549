@media (max-width: 600px) {
  .container,
  .container-fluid {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.nav-logo {
  font-weight: 500;
  font-size: 18px;
}

.card-header {
  background-color: #e9ecef;
}

.white-header .card-header {
  background-color: white;
}

.rundnaDropdown,
.rundnaDropdown .k-combobox {
  width: 100%;
}

.k-widget.form-control {
  border: none;
  border-radius: 0.25rem;
}

kendo-textbox.form-control {
  display: flex;
}

// this is needed after angular upgrade to 13 (likely bc of mismatch with kendo bootstrap theme version)
kendo-textbox .k-input-suffix {
  margin-right: 15px;
  color: gray;
}

.form-group input[type='radio'] + span {
  padding-left: 5px;
}

.split-button {
  display: inline;
}

.custom-invalid-message {
  font-size: 80%;
  color: #bb3734;
}

.indented-item {
  padding-left: 10px;
}

.date {
  border: 2px solid black;
  border-radius: 4px;
  padding: 0.75rem;
  text-align: center;
}

.date-month {
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 0;
  text-transform: uppercase;
}

.date-day {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.workoutDisplay {
  background-color: var(--bg-whiteElevated);
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(26, 35, 45, 0.25);
  max-width: 43.75em;
  margin: 0 auto;
  z-index: 2;
}
.workoutDisplay--client {
  border: 0;
  box-shadow: none;
}
.list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.well {
  margin-bottom: 1.25rem;
}

.bucket {
  display: flex;
}

.bucket-media {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  margin-right: 1.25rem;
}

.bucket--s > .bucket-media {
  margin-right: 0.75rem;
}
.workoutDisplay--client .workoutDisplay-exercise {
  border: 0;
  padding: 0;
}

.exercisePrefix {
  background-color: var(--bg-d1);
  border-radius: 6px;
  color: var(--text-l1);
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.25rem;
  position: relative;
  text-align: center;
  width: 1.875rem;
}

.bucket-content {
  flex-grow: 1;
  max-width: 100%;
  min-width: 0;
}

.split--nowrap {
  flex-wrap: nowrap;
}
.split {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
}

.split > :first-child {
  flex-grow: 1;
}

.split > :last-child {
  flex-shrink: 0;
}
.split > * {
  min-width: 0;
}

.exerciseStatus.is-completed {
  color: green;
  transition: background 0.1s ease-in-out;
}
.exerciseStatus {
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  text-align: center;
  width: 30px;
}

svg:not(:root).svg-inline--fa {
  overflow: visible;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

// this is a temp solution to hide sort icons on tables
.table .thead-light [data-sort]::after {
  display: none;
}

.table-responsive {
  overflow-x: unset !important;
}

.btn-group-toggle .btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #007bff;
  color: #ffffff;
}

.toggle-label {
  vertical-align: bottom;
  margin-bottom: 0.3rem;
  margin-right: 5px;
  font-weight: 300;
}

// turn tables into cards at certain breakpoints
// mobile-table has one column per row at small, mobile-xs-table keeps two columns per row at small

.mobile-grid-label {
  display: none;
}

@include media-breakpoint-down(md) {
  .mobile-table tr td:first-child,
  .mobile-xs-table tr td:first-child {
    padding: 10px 0px;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    vertical-align: top;
    width: calc(50% - 4px);
    display: inline-block;
    padding: 8px 0;
  }

  @include media-breakpoint-down(sm) {
    .mobile-table tr td {
      width: 100%;
      display: block;
      padding: 6px 0;
    }
  }

  .mobile-table tbody tr:last-child,
  .mobile-xs-table tbody tr:last-child {
    border: 1px solid #ced4da;
  }

  .mobile-grid-label {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #9ca1ac;
    font-weight: bold;
  }

  .mobile-table tr td span.mobile-grid-label,
  .mobile-xs-table tr td span.mobile-grid-label {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
    color: #9ca1ac;
    font-weight: bold;
  }

  .mobile-table tr,
  .mobile-xs-table tr {
    background: #fff;
    padding: 8px 15px;
    display: block;
    box-sizing: border-box;
    position: relative;
    border: 1px solid #ced4da;
    border-radius: 8px;
    margin: 0 auto 12px;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    border-top: 0;
  }

  .mobile-table tr.header-row,
  .mobile-xs-table tr.header-row {
    display: none;
  }

  .mobile-table tr td,
  .mobile-xs-table tr td {
    word-break: normal;
  }
}

// end table to cards logic

.right-icon-spacing {
  width: calc(100% - 80px);
  display: inline-flex;
  position: relative;
}

.right-icon {
  justify-content: flex-end;
  width: 40px;
  margin-bottom: 0;
  align-self: center;
}

i.right-icon {
  text-align: right;
  font-size: 18px;
}

.dark-text div.bg-success.progress-bar {
  color: black !important;
  background-color: #64c97b !important;
  font-weight: 600;
}

.dark-text div.bg-primary.progress-bar {
  color: black !important;
  background-color: #3f9cff !important;
  font-weight: 600;
}

// to override rundna.com styling on survey
rundna-website-wrapper add-survey .dark-text div.bg-primary.progress-bar {
  flex: none;
  margin: inherit;
}

.mb-1 .progress {
  margin-bottom: 00.25rem;
}

// promise button styling
.promise-btn-spinner {
  display: none;
}

.is-loading .promise-btn-spinner {
  display: inline-block;
}

// table sorting arrows
tr.sortable {
  position: relative;
  cursor: default;
}

th.sortable-asc,
th.sortable-desc,
th.sortable-none {
  padding-left: 25px;
}

th.sortable-desc,
th.sortable-asc,
th.sortable-none {
  cursor: pointer;
  position: relative;
  padding-right: 20px;
}

th.sortable-desc.sticky-top,
th.sortable-asc.sticky-top,
th.sortable-none.sticky-top {
  position: sticky;
}

th.sortable-none:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #9ca1ac;
  position: absolute;
  left: 10px;
  top: 6px;
  cursor: pointer;
  content: '\f106';
}

th.sortable-none:after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #9ca1ac;
  position: absolute;
  left: 10px;
  top: 13px;
  cursor: pointer;
  content: '\f107';
}

th.sortable-asc:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #5e72e4;
  position: absolute;
  left: 10px;
  top: 6px;
  cursor: pointer;
  content: '\f106';
  font-weight: bold;
  -webkit-text-stroke: 1px #5e72e4; /* background color */
}

th.sortable-asc:after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #9ca1ac;
  position: absolute;
  left: 10px;
  top: 13px;
  cursor: pointer;
  content: '\f107';
}

th.sortable-desc:before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #9ca1ac;
  position: absolute;
  left: 10px;
  top: 6px;
  cursor: pointer;
  content: '\f106';
}

th.sortable-desc:after {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  font-size: 10px;
  color: #5e72e4;
  position: absolute;
  left: 10px;
  top: 13px;
  cursor: pointer;
  content: '\f107';
  font-weight: bold;
  -webkit-text-stroke: 1px #5e72e4; /* background color */
}

.two-line-header th.sortable-none:before,
.two-line-header th.sortable-asc:before,
.two-line-header th.sortable-desc:before {
  top: 13px;
}

.two-line-header th.sortable-none:after,
.two-line-header th.sortable-asc:after,
.two-line-header th.sortable-desc:after {
  top: 20px;
}

.xs-textbox {
  min-width: 60px;
  max-width: 60px;
}

.small-textbox {
  min-width: 80px;
  max-width: 80px;
}

.medium-textbox {
  min-width: 125px;
  max-width: 125px;
}

.small-numeric {
  min-width: 90px;
  max-width: 90px;
  display: inline-block;
}

.medium-numeric {
  min-width: 120px;
  max-width: 120px;
  display: inline-block;
}

.k-numerictextbox.no-dial .k-numeric-wrap .k-select {
  display: none;
}

.numeric-input-label {
  padding-left: 5px;
  padding-right: 5px;
}

.checkbox-buttons label input {
  display: none;
}

div.btn-group.checkbox-buttons.rpe {
  max-width: 350px;
}

.single-checkbox {
  height: 20px;
  width: 20px;
}

kendo-sortable {
  height: 100%;
}

@include media-breakpoint-up(sm) {
  div[kendodraggable]:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 0.25rem !important;
    padding-bottom: 0.25rem;
  }
}

div[kendodraggable]:last-child {
  height: 100%;
}

.small-link {
  font-size: 14px;
}

.modal-sm-custom .modal-dialog {
  max-width: 400px;
}

.modal-md-custom .modal-dialog {
  max-width: 600px;
}

.modal-full-custom .modal-dialog {
  max-width: 95%;
}

/* use .modal-xl if you need modal to be wider than 'lg' */
@include media-breakpoint-up(lg) {
  .modal-xl-custom .modal-lg {
    max-width: 950px;
  }

  .modal-max-xl-custom .modal-lg {
    max-width: 950px;
  }
}

@include media-breakpoint-up(xl) {
  .modal-max-xl-custom .modal-lg {
    max-width: 1150px;
  }
}

/* used for hammer and axe paid programs pages */
.body-hax {
  background-color: #fafafa !important;
}

.ckembed {
  img {
    max-width: 100%;
    height: auto;
  }
  .image:not(.image-style-side) {
    margin-left: auto;
    margin-right: auto;
  }
  .image.image-style-side {
    float: right;
    margin: 1rem;
  }
  .image_resized {
    img {
      width: 100% !important;
    }
  }
  p {
    color: black !important;
    line-height: 1.5;
    font-weight: 400;
  }
  .text-huge {
    font-size: 2.8rem;
  }
  .text-tiny {
    font-size: 0.7em;
  }
  .text-small {
    font-size: 0.85em;
  }
  .text-big {
    font-size: 1.4em;
  }
}

.file-drop {
  &.fileover {
    animation: shake 1s;
    animation-iteration-count: infinite;
    background-color: #ccc;
  }
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  label {
    color: white;
    width: 183px;
    height: 44px;
    border-radius: 21.5px;
    background-color: #2e3f50;
    padding: 8px 16px;
  }
  h3 {
    font-size: 20px;
    font-weight: 600;
    color: #38424c;
  }
}
.upload-wrapper {
  position: relative;
  padding: 1px;
  width: 100%;
  height: 100%;
}
.upload-progress {
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
}
.img-tools .toolbar {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  button {
    background: none;
    border: 0;
    color: white;
    font-size: 2rem;
  }
}
.img-tools:hover {
  cursor: pointer;
  .toolbar {
    display: flex;
  }
}

app-global-marketplace-layout li.pagination-next.page-item::before,
app-global-marketplace-layout li.pagination-prev.page-item::before,
app-global-marketplace-layout li.pagination-page.page-item::before {
  background-image: none;
}
