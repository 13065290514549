.form-control.ng-pristine.ng-invalid.ng-touched .rundnaDropdown.ng-invalid.ng-dirty kendo-combobox,
.form-control.ng-dirty.ng-invalid.ng-untouched,
.form-control.ng-dirty.ng-invalid.ng-touched,
.rundnaDropdown.ng-untouched.ng-invalid.ng-dirty kendo-combobox,
.rundnaDropdown.ng-touched.ng-dirty.ng-invalid kendo-combobox,
.rundnaDropdown.ng-untouched.ng-invalid.ng-dirty kendo-multiselect,
.rundnaDropdown.ng-touched.ng-dirty.ng-invalid kendo-multiselect,
.rundnaDropdown.ng-touched.ng-dirty.ng-invalid select,
.rundnaDropdown.ng-untouched.ng-invalid.ng-dirty select,
minutes-seconds-textbox.ng-touched.ng-dirty.ng-invalid kendo-textbox,
minutes-seconds-textbox.ng-untouched.ng-dirty.ng-invalid kendo-textbox,
.invalid-border input.form-control,
div.btn-group.checkbox-buttons.ng-invalid.ng-touched,
azure-file-upload.ng-untouched.ng-invalid.ng-dirty .upload-wrapper,
azure-file-upload.ng-touched.ng-invalid.ng-dirty .upload-wrapper,
flag-enum-button-control.ng-touched.ng-dirty.ng-invalid .btn-group,
flag-enum-button-control.ng-untouched.ng-dirty.ng-invalid .btn-group {
  border: 1px solid #dc3545;
  border-radius: 0.25rem;
}

.form-control.ng-dirty.ng-invalid.ng-untouched.k-datepicker {
  border: none;
}

kendo-editor.ng-touched.ng-dirty.ng-invalid,
kendo-editor.ng-untouched.ng-dirty.ng-invalid,
custom-editor.rundnaDropdown.ng-touched.ng-dirty.ng-invalid .ck.ck-editor,
custom-editor.rundnaDropdown.ng-untouched.ng-dirty.ng-invalid .ck.ck-editor {
  border: 1px solid #dc3545;
}

kendo-switch.form-control,
.form-control.ng-invalid.ng-dirty .k-dropdown-wrap,
.rundnaDropdown,
.select-group {
  border: none;
}

.invalid-message {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bb3734;
}

.invalid-message-visible {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #bb3734;
}

.valid-message-visible {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: green;
}

.ng-dirty.ng-invalid + .invalid-message {
  display: block;
}

.ng-dirty.ng-invalid.ng-untouched .multi-option,
.ng-dirty.ng-invalid.ng-touched .multi-option {
    border: 1px solid #dc3545;
    border-radius: 0.25rem;
}

.ng-dirty.ng-valid.ng-untouched .multi-option,
.ng-dirty.ng-valid.ng-touched .multi-option,
.ng-pristine.ng-valid.ng-untouched .multi-option,
.ng-pristine.ng-invalid.ng-untouched .multi-option {
    border: none;
}
